<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>FILTRAR DATOS DE COMPRAS</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.supplier_id"
                            :items="suppliers"
                            label="Proveedor"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menuFrom"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    label="Desde"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    hide-details                         
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="menuFrom = false" no-title locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menuUntil"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_until"
                                    label="Hasta"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    hide-details                         
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_until" @input="menuUntil = false" no-title locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-combobox v-model="filters.type_document"
                            :items="type_documents"
                            outlined
                            label="Tipo de Documento"
                            hide-details="auto"
                            clearable
                        ></v-combobox>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field v-model="filters.document" 
                            label="Número de documento" 
                            outlined
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.product_id"
                            :items="products"
                            label="Producto"
                            outlined
                            item-text="description"
                            item-value="product_id"
                            clearable
                            hide-details="auto"
                            :loading="isLoading"
                            :search-input.sync="search_product"
                            hide-no-data
                            hide-selected
                            placeholder="Ingrese el producto a buscar"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import InventoryApi from '../../apis/Inventories';

export default {
    name: 'PurchasesFilters',
    data () {
        return {
            drawer: false,
            menuFrom: false,
            menuUntil: false,
            filters: {
                company_id: null,
                supplier_id: null,
                date_from: this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
                date_until: this.$moment().format('YYYY-MM-DD'),
                product_id: null,
                type_document: null,
                document: null
            },
            isLoading: false,
            search_product: null,
            products: [],
            type_documents: ['BOLETA', 'FACTURA']            
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('suppliers', [
            'suppliers'
        ])
    },
    watch: {
        async search_product (value) {
            if (value == null || value == undefined || value.length == 0) {
                return;
            }

            if (this.products.length > 0) {
                return;
            }

            if (this.isLoading) return

            this.isLoading = true

            let filters = {
                description: value
            }
            InventoryApi.getProductsFromOffice(filters)
                .then( response => {
                    this.products = response.data;
                })
                .catch ( error => {
                    console.log(error);
                })
                .finally( () => (this.isLoading = false));
        },
    },
    methods: {
        ...mapActions('report-purchases', [
            'getPurchases',
            'setFilters'
        ]),
        show () {
            this.filters.company_id = this.company_id;
            this.drawer = !this.drawer;            
        },
        customText(item){
            let text = item.name
            
            return text
        },
        closeForm () {
            this.drawer = false;
        },
        clearData () {
            this.filters.supplier_id = null;
            this.filters.date_from = this.$moment().subtract(30, 'days').format('YYYY-MM-DD');
            this.filters.date_until = this.$moment().format('YYYY-MM-DD');
            this.filters.product_id = null;
            this.filters.type_document = null;
            this.filters.document = null;
            this.menuFrom = false;
            this.menuUntil = false;
            this.setFilters(null);
        },
        handleClearFilters () {
            this.clearData();
            this.getPurchases(this.filters);
        },
        async handleSearch () {
            let filters_data = Object.assign({}, this.filters);
            this.setFilters(filters_data);
            await this.getPurchases(this.filters);
            this.closeForm();
        }
    }
}
</script>