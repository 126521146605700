<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Compras</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn dark color="#023145" width="140" class="float-right" @click="handleShowFilters">
            Filtros
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-1">
          <v-btn
              dark
              color="success"
              width="140"
              class="float-right"
              @click="handleDownloadReport">
            Descargar XLS
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-1">
          <v-card>
            <v-data-table
                :headers="headers"
                :items="purchases"
                :items-per-page="10"
                class="elevation-1 w-table"
                height="57vh"
                fixed-header
                :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait">
              <template v-slot:top>
                                <span class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las compras
                                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.register_date="{item}">
                <span>{{ item.register_date | moment("DD/MM/YYYY") }}</span>
              </template>

              <template v-slot:item.supplier_id="{item}">
                                <span v-if="item.supplier">
                                    {{ item.supplier.name }}
                                </span>
              </template>

              <template v-slot:item.storehouse_id="{item}">
                                <span v-if="item.storehouse">
                                    {{ item.storehouse.name }}
                                </span>
              </template>

              <template v-slot:item.ruc="{item}">
                                <span v-if="item.supplier">
                                    {{ item.supplier.ruc }}
                                </span>
              </template>

              <template v-slot:item.type_document="{item}">
                                <span>
                                    {{ item.type_document }}
                                </span>
              </template>

              <template v-slot:item.serie="{item}">
                                <span>
                                    {{ item.serie }}
                                </span>
              </template>

              <template v-slot:item.correlative="{item}">
                                <span>
                                    {{ item.correlative }}
                                </span>
              </template>

              <template v-slot:item.discount="{item}">
                <span>{{ item.discount | currency('S/') }}</span>
              </template>

              <template v-slot:item.subtotal="{item}">
                <span>{{ item.subtotal | currency('S/') }}</span>
              </template>

              <template v-slot:item.igv="{item}">
                <span>{{ item.igv | currency('S/') }}</span>
              </template>

              <template v-slot:item.total="{item}">
                <span class="label-total">{{ item.total | currency('S/') }}</span>
              </template>

              <template v-slot:item.detail="{item}">
                <v-icon size="17"
                        color="blue"
                        @click="handleViewDetail(item)">
                  fas fa-bars
                </v-icon>
                <v-icon size="17"
                        color="amber"
                        class="ml-2"
                        @click="handleViewUpdate(item)">
                  fas fa-pen
                </v-icon>
                <v-icon size="17"
                        class="ml-2"
                        color="error"
                        @click="handleDeletePurchase(item)">
                  far fa-times-circle
                </v-icon>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <purchases-filters ref="purchasesFilters"></purchases-filters>
    <download-purchases ref="downloadPurchases"></download-purchases>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import router from '@/router/index';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import PurchasesFilters from '../../../components/reports/PurchasesFilters';
import DownloadPurchases from '../../../components/reports/DownloadPurchases';

export default {
  name: 'ReportPurchases',
  components: {
    PurchasesFilters,
    OfficeLogin,
    DownloadPurchases
  },
  watch: {
    options: {
      handler() {
        console.log(this.options)
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        this.loading = true
        const search = this.search_product
        console.log(this.filtersPaginated)
        if (this.filtersPaginated.supplier_id!=null || this.filtersPaginated.date_from!=null ||
            this.filtersPaginated.date_until!=null || this.filtersPaginated.product_id!=null ||
            this.filtersPaginated.type_document!=null || this.filtersPaginated.document!=null
        ){
          this.getPurchases({
            paginated: true, page: 1,
            itemsPerPage: this.perPage,
            company_id: this.company_id,
            date_from: this.filtersPaginated.date_from,
            date_until: this.filtersPaginated.date_until,
            product_id: this.filtersPaginated.product_id,
            supplier_id: this.filtersPaginated.supplier_id,
            type_document: this.filtersPaginated.type_document,
            document: this.filtersPaginated.document
          }).then(res => this.loading = false)
              .catch(err => this.loading = false);
        }
        else{
          if (search != null && search.trim().length > 0) {

            this.getPurchases({
              paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
              name: search, company_id: this.company_id,
              date_from: this.date_from,
              date_until: this.date_until,
            }).then(res => this.loading = false)
                .catch(err => this.loading = false);
          } else {
            this.getPurchases({
              paginated: true, page: this.pageSelected, itemsPerPage: this.perPage, company_id: this.company_id,
              date_from: this.date_from,
              date_until: this.date_until,
            }).then(res => this.loading = false)
                .catch(err => this.loading = false);
          }
        }

      },
      deep: true,
    }
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: 'Reportes',
          disabled: true,
          href: '',
        }
      ],
      headers: [
        {text: 'FECHA', value: 'register_date', sortable: false},
        {text: 'ALMACÉN', value: 'storehouse_id', sortable: false},
        {text: 'PROVEEDOR', value: 'supplier_id', sortable: false},
        {text: 'RUC', value: 'ruc', sortable: false},
        {text: 'DOCUMENTO', value: 'type_document', sortable: false},
        {text: 'SERIE', value: 'serie', sortable: false},
        {text: 'N° DOC.', value: 'correlative', sortable: false},
        {text: 'DESCUENTO', value: 'discount', sortable: false, align: 'end'},
        {text: 'SUBTOTAL', value: 'subtotal', sortable: false, align: 'end'},
        {text: 'IGV', value: 'igv', sortable: false, align: 'end'},
        {text: 'TOTAL', value: 'total', sortable: false, align: 'end'},
        {text: '', value: 'detail', sortable: false, align: 'end', width: 100},
      ],
      date_from: this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
      date_until: this.$moment().format('YYYY-MM-DD'),
      loading: false,
      dataTable: {page: 1},
      options: {},
      pageSelected: 1,
      perPage: 10,
      search_product: null
    }
  },
  computed: {
    ...mapState('authentication', [
      'company_id'
    ]),
    ...mapState('report-purchases', [
      'purchases',
      'file_purchases',
      'filters',
      'paginated',
      'filtersPaginated'
    ])
  },
  mounted() {
    this.changeFilterPaginated({
      company_id: null,
      supplier_id: null,
      date_from:null,
      date_until:null,
      product_id: null,
      type_document: null,
      document: null
    })
  },

  methods: {
    ...mapActions('report-purchases', [
      'getPurchases',
      'emptyFilePurchases',
      'purchasesExcel',
      'setFilters',
      'selectPurchase',
      'setPurchases',
      'changeFilterPaginated',
        'changeFilterPaginated'
    ]),
    ...mapActions('suppliers', [
      'getSuppliers'
    ]),
    ...mapActions('purchases', [
      'delete',
      'selectPurchaseToEdit'
    ]),
    handleShowFilters() {
      this.$refs.purchasesFilters.show();
    },
    async handleDownloadReport() {
      this.$refs.downloadPurchases.show();
      /*
      this.emptyFilePurchases();
      let filters_data = Object.assign({}, this.filters);
      filters_data.company_id = this.company_id;
      await this.purchasesExcel(filters_data);
      if (this.file_purchases) {
          let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_purchases;
          window.open(url);
      }
      */
    },
    handleViewDetail(purchase) {
      this.selectPurchase(purchase);
      router.push('/app/reportes/detalle-compras');
    },
    handleViewUpdate(purchase) {
      this.selectPurchaseToEdit(purchase);
      router.push('/app/logistica/compras');
    },
    handleDeletePurchase(purchase) {
      let message = purchase.type_document + " " + purchase.serie + "-" + purchase.correlative;
      this.$swal({
        title: "¿Desea confirmar?",
        text: "Confirma que desea eliminar la compra cuyo documento es: " + message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          if (result.value === true) {
            await this.delete(purchase);
            this.loading = true
            this.getPurchases({
              company_id: this.company_id, paginated: true
            }).then(res => this.loading = false)
                .catch(err => this.loading = false);
          }
        }
      })
    }
  },
  created() {
    this.selectPurchase(null);
    this.setFilters({
      company_id: this.company_id,
      date_from: this.date_from,
      date_until: this.date_until,
      paginated: true
    });
    this.getSuppliers();
    // this.loading=true
    // this.getPurchases(this.filters).then(res=>this.loading=false)
    //     .catch(err=>this.loading=false);
  },
  destroyed() {
    this.setPurchases([]);
  }
}
</script>

<style scoped>
.label-ruc {
  font-weight: 600;
  color: #3F51B5;
}

.label-total {
  font-weight: 600;
  color: #4CAF50;
}
</style>